<template lang="pug">
  .app-select-batch
    AppButton.select-all(
      title="components.checkboxes_group.select_all"
      name="select-all"
      @click="$emit('select-all')"
    )
    AppButton.clear-all(
      title="components.checkboxes_group.clear_all"
      name="clear-all"
      @click="$emit('clear-all')"
    )
</template>

<script>
  export default {
    components: {
      AppButton: () => import("@/components/elements/AppButton")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .app-select-batch
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0 12px

    .select-all,
    .clear-all
      height: 30px
      padding: 6px 10px
      display: flex
      align-items: center
      justify-content: center
      white-space: nowrap
      background: $default-white
      color: $default-black
      border: 1px solid $border-element-color

      ::v-deep
        & *
          font-size: 0.8rem !important

      &:hover
        background: $default-gray-light
        border-color: $default-gray-medium

      &:focus
        outline: none
        box-shadow: none

      &:active
        background: $default-white
        color: $default-black
        outline: none
</style>
